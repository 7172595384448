<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  name: 'App'
}
</script>

<style lang="less">
html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  font-size: 14px;
  font-family: PingFangSC, PingFangSC-Regular;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#app {
  height: 100%;
  width: 100%;

}

p,
li,
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
a {
  text-decoration: unset;
}
.default-dialog-form-style {
  width: 700px;
  min-height: 170px;
  max-height: 600px;
}

.inside-page {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: #fff;
  .inside-page-title {
    flex: 0 20px;
    height: 20px;
    padding: 14px 20px;
    border-bottom: 1px solid #e6e7ef;
    font-size: 16px;
    font-weight: 600;
    text-align: left;
    color: #2a2b2f;
  }
  .inside-page-content {
    flex: 1 auto;
    padding: 14px 20px;
  }
}

.el-dialog__header {
  background-color: #fff;
  border-bottom: 1px solid #f2f3f4;
  font-size: 16px;
}

.el-dialog__body {
  padding: 24px 20px;
  border-bottom: 1px solid #f2f3f4;
}

.el-step__title {
  font-size: 14px !important;
}

.el-dialog__title {
  font-size: 16px !important;
}

.el-table__header {
  thead {
    th {
      background-color: #f7f7fb !important;
      line-height: 50px;
      font-size: 14px;
      font-weight: 600;
      color: #2a2b2f;
    }
  }
}

.iconfont {
  font-size: 14px;
}

.form-item-title {
  font-size: 16px;
  font-weight: 700;
  i {
    width: 5px;
    background-color: #465fd2;
    display: inline-block;
    height: 16px;
    vertical-align: middle;
    margin-top: -3px;
    margin-right: 10px;
  }
}

.el-step__head.is-finish,
.el-step__title.is-finish,
.el-step__head.is-process,
.el-step__title.is-process {
  color: #3350cb !important;
  border-color: #3350cb !important;
}

.el-step__head.is-wait,
.el-step__title.is-wait {
  color: #61636e !important;
  border-color: #61636e !important;
}

.form-info-edit {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .el-form-item {
    flex: 0 0 50%;
  }
  .form-info-edit-row {
    flex: 0 0 100%;
  }
}

.agreen-tooltip {
  background-color: #303133ab !important;
  .popper__arrow {
     border-top-color: #30313300 !important;
    &::after {
      border-top-color: #303133ab !important;
    }
  }
}

.opt-btn-icon  {
  color: #9597a1 !important;
  &+.opt-btn-icon {
    margin-left: 20px !important;
  }
  &:hover {
    color: #3350cb !important;
  }
}

</style>
