import Vue from 'vue'
import VueRouter from 'vue-router'
import MainView from '@/components/ft-frame.vue'
import routerMain from './router.main'
import axios from 'axios'
import { MessageBox } from 'element-ui'

const vuePush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return vuePush.call(this, location).catch(err => err)
}

const { routerList } = routerMain

const flattenTree = (data, prefix) => {
  const list = data.reduce(
    (pre, item) => [
      ...pre,
      item,
      ...flattenTree(item.children || [], item.path)
    ],
    []
  )
  list.forEach((row) => {
    row.aPath = prefix + (row.aPath || row.path)
  })
  return list
}
const flatRoutes = flattenTree(routerList, '')
const routers = flatRoutes
  .filter((row) => row.component)
  .map((row) => {
    return { path: row.aPath, component: row.component, redirect: row.redirect }
  })

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    redirect: '/home/overview',
    component: MainView,
    children: routers
  },
  {
    path: '*',
    name: '404',
    component: () => import('@/webapp/error.vue')
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const auth = Vue.prototype._auth
  const userId = auth.getUserId()
  const userInfo = auth.userInfo
  if (!userId) {
    auth.clearToken()
    auth.userInfo = null
    window.location.href = `//sso.ftling.com?url=${encodeURIComponent(
      window.location.href
    )}`
  } else if (!userInfo) {
    axios
      .get('/operation/v1/user/current')
      .then(({ data }) => {
        if (!data.result) {
          window.location.href = `//sso.ftling.com?url=${encodeURIComponent(
            window.location.href
          )}`
          return
        }
        axios.get('/api/current_user').then((res) => {
          const payload = res.data.payload
          if (payload.orgType === 1) {
            data.result.current = {
              id: data.result.info.id,
              type: '个人',
              typeId: 1,
              name: data.result.info.trusName,
              status: data.result.info.auth,
              entryable: true
            }
          } else if (payload.orgType === 2) {
            const row = data.result.orgas.find(row => row.id === payload.orgId)
            data.result.current = {
              id: row.id,
              type: '企业',
              typeId: 2,
              name: row.name,
              status: row.authStatus,
              entryable: row.entryable
            }
          } else {
            MessageBox.confirm('您不是企业/个人用户，不能进入此管理平台', {
              title: '提示',
              type: 'error',
              showCancelButton: false,
              showConfirmButton: true
            }).finally(() => {
              window.location.href = '//mall.ftling.com'
            })
            return
          }
          auth.userInfo = data.result
          next()
        })
      })
      .catch(() => {
        window.location.href = `//sso.ftling.com?url=${encodeURIComponent(
          window.location.href
        )}`
      })
  } else {
    next()
  }
})

export default router
