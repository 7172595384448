const orderMange = () => import('@/webapp/service/order.vue')
const writService = () => import('@/webapp/service/writ-service.vue')
const letter = () => import('@/webapp/service/letter.vue')
const serviceList = () => import('@/webapp/service/service-list.vue')
const purchased = () => import('@/webapp/service/purchased.vue')
const counselor = () => import('@/webapp/service/counselor.vue')
const visit = () => import('@/webapp/service/visit.vue')
const consult = () => import('@/webapp/service/consult.vue')

export default [
  {
    path: '/service',
    meta: {
      id: '23dd0546-57fc-4a43-8c16-348710716d10',
      icon: '',
      name: 'service',
      title: '法顾服务',
      type: [0],
      apis: []
    },
    children: [
      {
        path: '/law-service',
        meta: {
          id: '863f2642-6b9e-44bf-bfad-1ffc8ac0eb16',
          icon: 'icon-fawufuwuicon',
          iconColor: '#45acf6',
          name: 'law-service',
          title: '文书服务',
          type: [0]
        },
        children: [
          {
            path: '/writ-service',
            meta: {
              id: 'c4d12af5-26c6-45b9-8ff9-9035d5e357ce',
              icon: '',
              name: 'writ-service',
              title: '服务发起',
              type: [0],
              actions: [
                {
                  id: '1e8e7615-fe41-4641-abd3-c31d8ca46378',
                  name: 'create',
                  title: '发起服务'
                }
              ]
            },
            component: writService
          },
          {
            path: '/letter',
            meta: {
              id: '6545a67a-4cb1-4637-acbc-7c81777d7151',
              icon: '',
              name: 'letter',
              title: '代发律师函',
              type: [0]
            },
            component: letter
          },
          {
            path: '/service-list',
            meta: {
              id: '5dc571db-5fba-4d1d-a67c-f349abade491',
              icon: '',
              name: 'service-list',
              title: '服务查询',
              type: [0],
              badge: 'serviceCount'
            },
            component: serviceList
          }
        ]
      },
      {
        path: '/visit',
        meta: {
          id: '4b328096-27aa-4601-96ae-25af4de30064',
          icon: 'icon-fawufuwuicon',
          iconColor: '#45acf6',
          name: 'visit',
          title: '上门服务',
          type: [2]
        },
        children: [
          {
            path: '/record',
            meta: {
              id: '8835ba4e-c93d-4fe6-a651-a54592648a85',
              icon: '',
              name: 'record',
              title: '服务记录',
              type: [2],
              actions: [
                {
                  id: '9750ef38-522b-4aad-b94b-8f9e27b2ccd5',
                  name: 'create',
                  title: '预约上门'
                }
              ]
            },
            component: visit
          }
        ]
      },
      {
        path: '/order',
        meta: {
          id: '',
          icon: 'icon-dingdanyufuwuicon',
          iconColor: '#ff7172',
          name: 'order',
          title: '订单与服务',
          type: [1]
        },
        children: [
          {
            path: '/manage',
            meta: {
              id: '',
              icon: '',
              name: 'manage',
              title: '订单管理',
              type: [1]
            },
            component: orderMange
          },
          {
            path: '/purchased',
            meta: {
              id: '',
              icon: '',
              name: 'purchased',
              title: '已购服务',
              type: [1]
            },
            component: purchased
          }
        ]
      },
      {
        path: '/counselor',
        meta: {
          id: '',
          icon: 'icon-falvguwenfuwuicon',
          iconColor: '#ff9640',
          name: 'counselor',
          title: '专属顾问服务',
          type: [2]
        },
        component: counselor
      },
      {
        path: '/consult',
        meta: {
          id: 'c0d9e1c3-8935-48dc-bec1-c35cf01359d5',
          icon: 'icon-falvguwenfuwuicon',
          iconColor: '#ff9640',
          name: 'consult',
          title: '咨询服务',
          type: [2]
        },
        component: consult
      }
    ]
  }

]
