// 系统管理

const accountInfo = () => import('@/webapp/system/account-info.vue')
const accountSecure = () => import('@/webapp/system/account-secure.vue')
const memberRole = () => import('@/webapp/system/member-role.vue')
const memberList = () => import('@/webapp/system/member-list.vue')
const messageList = () => import('@/webapp/system/message.vue')
const orgaAccount = () => import('@/webapp/system/account-orga.vue')
const Api = require('../apis/path')
export default [
  {
    path: '/system',
    redirect: '/account/info',
    meta: {
      id: '874ca939-9b02-461a-b603-3eeb74b42123',
      icon: '',
      name: 'system',
      title: '系统管理',
      type: [0],
      apis: []
    },
    children: [
      {
        path: '/account',
        meta: {
          id: '41068e48-d2d1-4b0c-a193-7b990bdbaafe',
          icon: 'iconfont icon-zhanghaoshezhiicon',
          iconColor: '#527aee',
          name: 'account',
          title: '帐号设置',
          type: [0],
          apis: []
        },
        children: [
          {
            path: '/info',
            meta: {
              id: '',
              icon: '',
              name: 'info',
              title: '个人信息',
              type: [0],
              apis: []
            },
            component: accountInfo
          },
          {
            path: '/secure',
            meta: {
              id: '',
              icon: '',
              name: 'secure',
              title: '帐号安全',
              type: [0],
              apis: []
            },
            component: accountSecure
          },
          {
            path: '/orga-account',
            meta: {
              id: '7f76a157-6802-4d14-b544-dcf2e45c79b8',
              icon: '',
              name: 'orga-account',
              title: '企业帐号',
              type: [2],
              apis: [
                `get:${Api.path.user}/orga/{orgaId}`,
                `get:${Api.path.user}/orga/{orgaId}/auth/log/current`
              ],
              actions: [
                {
                  id: 'dfbd2934-64b8-4dfa-ab65-8d474a8e4fcf',
                  name: 'edit',
                  title: '编辑',
                  apis: [`put:${Api.path.user}/orga/{orgaId}`]
                },
                {
                  id: 'bf087a0b-e28a-4796-9573-ebebb1d8789e',
                  name: 'auth',
                  title: '企业认证',
                  apis: [
                    `post:${Api.path.user}/orga/{orgaId}/auth`,
                    `get:${Api.path.user}/orga/{orgaId}/auth/log/{id}`,
                    `get:${Api.path.user}/orga/{orgaId}/auth/log/current`,
                    `post:${Api.path.user}/orga/{orgaId/auth/log/{id}/missive`,
                    `post:${Api.path.user}/orga/{orgaId}/auth/log/{id}/transfer`,
                    `put:${Api.path.user}/orga/{orgaId}/auth/log/{id}/transfer`,
                    `post:${Api.path.user}/orga/{orgaId}/auth/log/{id}/transfer/validate`,
                    `post:${Api.path.user}/orga/{orgaId}/auth/log/{id}/face`,
                    `get:${Api.path.user}/common/auth/person/face/{merchantId}/{token}/status`,
                    `get:${Api.path.user}/orga/{orgaId}/auth/log`
                  ]
                }, {
                  id: '91ca7055-eeea-4419-bf25-6f1c47f9695a',
                  name: 'unregister',
                  title: '注销',
                  apis: [`post:${Api.path.user}/orga/{orgaId}/unregister`]
                }
              ]
            },
            component: orgaAccount
          },
          {
            path: '/message',
            meta: {
              id: '',
              icon: '',
              name: 'message',
              title: '消息中心',
              type: [0],
              apis: []
            },
            component: messageList
          }
        ]
      },
      {
        path: '/member',
        meta: {
          id: '73c2b43c-5499-494b-a0df-a1e81bbe5223',
          icon: 'iconfont icon-yuangongzhongxinicon',
          iconColor: '#45acf6',
          name: 'member',
          title: '员工中心',
          type: [2],
          apis: []
        },
        children: [
          {
            path: '/role',
            meta: {
              id: 'e01a7677-da31-48c8-a6a1-4ac8202d7f9f',
              icon: '',
              name: 'role',
              title: '角色管理',
              type: [2],
              apis: [
                `get:${Api.path.user}/operation`,
                `get:${Api.path.user}/orga/{orgaId}/role`,
                `put:${Api.path.user}/orga/{orgaId}/role/{id}`,
                `post:${Api.path.user}/orga/{orgaId}/role`,
                `get:${Api.path.user}/orga/{orgaId}/role/{id}`,
                `delete:${Api.path.user}/orga/{orgaId}/role/{id}`
              ],
              actions: [
                {
                  id: '03065cf5-2192-4a81-883b-581152f84e59',
                  name: 'add',
                  title: '新增角色',
                  apis: []
                },
                {
                  id: '7f8e859e-b290-4043-a94d-69524fe1034c',
                  name: 'edit',
                  title: '编辑角色',
                  apis: []
                },
                {
                  id: '36546170-a83a-4b51-9d82-f9c5da0b0e39',
                  name: 'del',
                  title: '删除角色',
                  apis: []
                }
              ]
            },
            component: memberRole
          },
          {
            path: '/list',
            meta: {
              id: '7ee5ea00-6458-4861-bb2c-67da28fa7f17',
              icon: '',
              name: 'member',
              title: '成员管理',
              type: [2],
              apis: [
                `get:${Api.path.user}/orga/{orgaId}/department`,
                `get:${Api.path.user}/orga/{orgaId}/role`,
                `post:${Api.path.user}/orga/{orgaId}/department`,
                `put:${Api.path.user}/orga/{orgaId}/department/{id}`,
                `delete:${Api.path.user}/orga/{orgaId}/department/{id}`,
                `get:${Api.path.user}/orga/{orgaId}/member`,
                `put:${Api.path.user}/orga/{orgaId}/member/{id}`,
                `post:${Api.path.user}/orga/{orgaId}/member`,
                `get:${Api.path.user}/orga/{orgaId}/member/{memberId}`,
                `delete:${Api.path.user}/orga/{orgaId}/member/{memberId}`,
                `put:${Api.path.user}/orga/{orgaId}/member/{memberId}/status`,
                `post:${Api.path.user}/orga/{orgaId}/invite`
              ],
              actions: [
                {
                  id: 'b1bd7160-734d-4cad-9553-bddb21f41d3b',
                  name: 'add',
                  title: '新增分类',
                  apis: []
                },
                {
                  id: '14800605-1d9b-4c22-8a7a-87aec00cf7e6',
                  name: 'edit',
                  title: '编辑分类',
                  apis: []
                },
                {
                  id: 'b57caf93-6fc2-4553-baff-0d75e5c23f72',
                  name: 'del',
                  title: '删除分类',
                  apis: []
                },
                {
                  id: 'ba863357-a092-4cc7-903a-ddb9b70a3c15',
                  name: 'add',
                  title: '新增成员',
                  apis: []
                },
                {
                  id: '3bda17a1-fd40-40aa-a8c6-07cbf90f14bd',
                  name: 'edit',
                  title: '编辑成员',
                  apis: []
                },
                {
                  id: '5e76f606-965c-4e85-8d40-43d1d26b000b',
                  name: 'del',
                  title: '删除成员',
                  apis: []
                },
                {
                  id: 'd7c66e85-d677-4e24-a1e0-ca0ea8fedcf0',
                  name: 'del',
                  title: '启用/停用',
                  apis: []
                },
                {
                  id: '0a53220a-84ef-4375-a30e-d6ca4297f85c',
                  name: 'invite',
                  title: '邀请成员'
                }
              ]
            },
            component: memberList
          }
        ]
      }
    ]
  }
]
