export default [
  {
    path: '/classroom',
    meta: {
      id: '',
      icon: '',
      name: 'classroom',
      title: '企业讲堂',
      type: [2],
      apis: []
    },
    children: [
      {
        path: '/list',
        meta: {
          id: '',
          icon: 'iconfont icon-qiyejiangtang',
          iconColor: '#507bfd',
          name: 'list',
          title: '企业讲堂',
          type: [2],
          apis: []
        },
        component: () => import('@/webapp/classroom/list.vue')
      },
      {
        path: '/train',
        meta: {
          id: '',
          icon: 'iconfont icon-falvpeixun',
          iconColor: '#259df8',
          name: 'train',
          title: '法律培训',
          type: [2],
          apis: []
        },
        component: () => import('@/webapp/classroom/train.vue')
      },
      {
        path: '/learn',
        meta: {
          id: '',
          icon: 'icon-pufaxuefaicon1',
          iconColor: '#FF7172',
          name: 'learn',
          title: '学习进度',
          type: [2],
          apis: []
        },
        children: [
          {
            path: '/center',
            meta: {
              id: '',
              icon: '',
              name: 'center',
              title: '学习中心',
              type: [2],
              apis: []
            },
            component: () => import('@/webapp/classroom/learn/center.vue')
          },
          {
            path: '/course',
            meta: {
              id: '',
              icon: '',
              name: 'course',
              title: '已购课程',
              type: [2],
              apis: []
            },
            component: () => import('@/webapp/classroom/learn/course.vue')
          }
        ]
      }
    ]
  }
]
