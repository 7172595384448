export default {
  path: 'whiteList',
  name: 'whiteList',
  meta: {
    id: '',
    name: 'greyList',
    title: '白名单Api列表',
    apis: [
      'get:/operation/v1/common/code/image',
      'get:/operation/v1/upload/{filename}',
      'post:/operation/v1/common/code/{biz}',
      'put:/operation/v1/common/code/{biz}',
      'post:/operation/v1/gateway/user/password',
      'post:/operation/v1/common/validate/user/phone',
      'post:/operation/v1/common/validate/orga/name',
      'post:/operation/v1/common/register/user',
      'post:/operation/v1/common/register/orga',
      'get:/operation/v1/orga/invite/{code}',
      'post:/operation/v1/orga/invite/{code}',
      'post:/operation/v1/orga/invite/{code}',
      'post:/knowledge/v1/event/log',
      'get:/operation/v1/common/code/qrcode',
      'get:/operation/v1/orga/{oragId}/auth/log/{id}/missive'
    ]
  }
}
