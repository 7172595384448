export default [
  {
    path: '/assessment',
    redirect: '/assessment/overview',
    meta: {
      id: '8d3c02d2-1fe5-428c-a39e-e0bb81609d06',
      icon: '',
      name: 'assessment',
      title: '合规评估',
      type: [2],
      apis: []
    },
    children: [
      {
        path: '/overview',
        meta: {
          id: 'c9039004-b821-490c-b501-af9c018b67a3',
          icon: 'icon-zonglanicon2',
          name: 'overview',
          title: '总览',
          iconColor: 'rgb(80, 123, 253)',
          type: [2]
        },
        component: () => import('@/webapp/compliance/overview-other.vue')
      },
      {
        path: '/examination',
        meta: {
          id: 'a40fd5e1-9b3e-451d-ac1e-96c0f784afeb',
          icon: 'icon-fengxiantijian',
          name: 'examination',
          title: '企业风险体检',
          iconColor: '#ff7172',
          type: [2],
          apis: [
            'get:/knowledge/v1/questionnaire/index'
          ]
        },
        component: () => import('@/webapp/compliance/examination.vue')
      },
      {
        path: '/consult',
        meta: {
          id: '',
          icon: 'el-icon-s-comment',
          name: 'consult',
          title: '法律咨询',
          iconColor: '#507BFD',
          type: [2],
          link: '//zx.ftling.com/#/pcClient/user/zx',
          apis: []
        }
      },
      {
        path: '/issue',
        meta: {
          id: '',
          icon: 'icon-a-falvbaiwen-icon1',
          name: 'issue',
          title: '企业百问',
          type: [2],
          iconColor: '#27b7fc',
          apis: []
        },
        component: () => import('@/webapp/home/issue.vue')
      },
      {
        path: '/law',
        meta: {
          id: '',
          icon: 'icon-pufaxuefaicon1',
          name: 'law',
          title: '法规查询',
          type: [2],
          iconColor: '#FF9640',
          apis: [
          ]
        },
        component: () => import('@/webapp/home/law.vue')
      },
      {
        path: '/collect',
        meta: {
          id: '',
          icon: 'icon-shoucangjia1',
          name: 'collect',
          title: '收藏夹',
          type: [2],
          iconColor: '#FF7172',
          apis: []
        },
        component: () => import('@/webapp/home/homeCollect.vue')
      }
    ]
  }
]
