import fingerPrint from './fingerprintjs.v4'

let fingerprintCode = null

const mapTitle = {
  '/home/issue': (route) => {
    switch (route.query.type) {
      case 'detail':
        return {
          title: '企业法律百问详情页',
          keys: ['企业百问']
        }
      default:
        return {
          title: '企业法律百问页',
          keys: ['企业百问']

        }
    }
  },
  '/home/law': (route) => {
    switch (route.query.type) {
      case 'detail':
        return {
          title: '法律法规详情页',
          keys: ['法律法规']
        }
      default:
        return {
          title: '法律法规页',
          keys: ['法律法规']

        }
    }
  },
  '/compliance-manage/writ/ftwk': (route) => {
    if (route.query.id) {
      return {
        title: '单合同模板详情页',
        keys: ['单合同模板']
      }
    } else {
      return {
        title: '单合同模板页',
        keys: ['单合同模板']
      }
    }
  },
  '/compliance-manage/writ/contract-pacakge': (route) => {
    if (route.query.id) {
      return {
        title: '行业合同包详情页',
        keys: ['行业合同包']
      }
    } else {
      return {
        title: '行业合同包页',
        keys: ['行业合同包']
      }
    }
  },
  '/service/law-service/writ-service': {
    title: '文书服务页',
    keys: ['文书服务']
  },
  '/home/examination': {
    title: '企业风险体检页',
    keys: ['企业风险体检']
  },
  '/compliance-manage/compliance/contract-sign': {
    title: '合同签署页',
    keys: ['电子签署']
  }
}

export function getTitle (route) {
  const key = Object.keys(mapTitle).find((key) => {
    const reg = new RegExp('^' + key + '$', 'i')
    return reg.test(route.path)
  })

  if (key) {
    const titleObj = mapTitle[key]
    if (typeof titleObj === 'function') {
      return titleObj(route)
    } else {
      return titleObj
    }
  } else {
    return {}
  }
}

export async function getFingerPrint () {
  if (fingerprintCode) {
    return fingerprintCode
  } else {
    fingerprintCode = (await fingerPrint.load().then(fp => fp.get())).visitorId
    return fingerprintCode
  }
}

export function getPlatform () {
  // 抖音小程序 百度小程序 微信小程序 web端 h5端
  const userAgent = window.navigator.userAgent

  if ((/miniProgram/g).test(userAgent)) {
    return '微信小程序'
  } else if (/swan\//.test(userAgent) || /^webswan-/.test(userAgent)) {
    return '百度小程序'
  } else if (/ToutiaoMicroApp/.test(userAgent)) {
    return '抖音小程序'
  } else {
    const keys = ['Android', 'iPhone', 'SymbianOS', 'Windows Phone', 'iPad', 'iPod']
    if (!keys.some(key => userAgent.includes(key))) {
      return 'web端'
    } else {
      return 'h5端'
    }
  }
}

export function getAppName (auth) {
  // PC商城 H5企业端  H5个人端
  const userAgent = window.navigator.userAgent
  const keys = ['Android', 'iPhone', 'SymbianOS', 'Windows Phone', 'iPad', 'iPod']
  let prefix = 'PC'
  if (keys.some(key => userAgent.includes(key))) {
    prefix = 'H5'
  }
  const value = prefix + '个人端'
  const payload = auth.getPayload()
  if (payload && payload.orgType === 2) {
    return prefix + '企业端'
  }
  return value
}
