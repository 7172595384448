export default {
  path: 'greyList',
  name: 'greyList',
  meta: {
    id: '',
    name: 'greyList',
    title: '灰名单Api列表',
    apis: [
      'get:/operation/v1/user/current',
      'post:/operation/v1/user/current',
      'post:/operation/v1/user/current/password',
      'post:/operation/v1/user/current/phone',
      'get:/operation/v1/gateway/user/{userId}/operation',
      'post:/operation/v1/common/file',
      'get:/operation/v1/gateway/user/{userId}/orga/{orgaId}/operation',
      'get:/operation/v1/orga/{orgaId}/member',
      'post:/operation/v1/user/current/auth',
      'get:/operation/v1/orga/{orgaId}/role',
      'put:/operation/v1/common/code/{biz}',
      'post:/operation/v1/common/code/{biz}',
      'post:/operation/v1/user/current/unregister',
      'post:/operation/v1/common/code/cos',
      'get:/operation/v1/common/code/cos',
      'post:/knowledge/v1/user/up/{id}',
      'post:/operation/v1/common/validate/user/account',
      'get:/knowledge/v1/category/all',
      'get:/knowledge/v1/law/agreement/page/list',
      'post:/knowledge/v1/law/agreement',
      'get:/knowledge/v1/law/agreement/{id}',
      'put:/knowledge/v1/law/agreement/{id}',
      'post:/knowledge/v1/file/down',
      'post:/knowledge/v1/category',
      'delete:/knowledge/v1/law/agreement/batch',
      'delete:/knowledge/v1/law/agreement/batch/recover',
      'put:/knowledge/v1/law/agreement/batch',
      'delete:/knowledge/v1/law/agreement/clear/{id}',
      'delete:/knowledge/v1/law/agreement/all',
      'put:/knowledge/v1/category/{id}',
      'delete:/knowledge/v1/law/agreement/{id}',
      'get:/knowledge/v1/law/agreement/recycle/page/list',
      'post:/knowledge/v1/file/batch',
      'post:/operation/v1/common/format/minhtml',
      'put:/knowledge/v1/law/agreement/recover/{id}',
      'get:/knowledge/v1/mall/order/page/list/client',
      'get:/knowledge/v1/mall/order/{id}',
      'get:/knowledge/v1/mall/document/{id}',
      'put:/knowledge/v1/mall/order/cancel/{id}',
      'get:/knowledge/v1/mall/order/buy/list',
      'put:/knowledge/v1/law/document/use/{id}',
      'get:/knowledge/v1/user/collect/list',
      'get:/knowledge/v1/msg/message/page/list',
      'get:/knowledge/v1/msg/message/{id}',
      'get:/knowledge/v1/msg/message/unRead',
      'get:/knowledge/v1/index/data/org',
      'post:/knowledge/v1/law/agreement/service',
      'get:/knowledge/v1/law/agreement/service/page/list',
      'get:/knowledge/v1/law/agreement/service/{id}',
      'post:/knowledge/v1/law/agreement/service/refund/{id}',
      'put:/knowledge/v1/law/agreement/service/confirm/{id}',
      'get:/knowledge/v1/mall/order/buy/service/list',
      'get:/knowledge/v1/mall/product/{id}',
      'get:/knowledge/v1/user/services/info',
      'post:/knowledge/v1/user/experience',
      'get:/knowledge/v1/law/document/page/list',
      'get:/knowledge/v1/law/clause/page/search',
      'get:/knowledge/v1/mall/order/buy/service/{id}',
      'get:/knowledge/v1/law/document/{id}',
      'get:/knowledge/v1/user/services/num',
      'get:/knowledge/v1/law/agreement/flow/list/sensitive/words',
      'post:/knowledge/v1/law/agreement/flow/list/sensitive/words',
      'post:/knowledge/v1/law/agreement/flow/list/key/words',
      'get:/knowledge/v1/law/agreement/flow/list/risk',
      'put:/knowledge/v1/law/agreement/check/{id}',
      'post:/knowledge/v1/mall/order',
      'get:/knowledge/v1/mall/product/user/page/list',
      'get:/knowledge/v1/mall/product/user/page/info/{id}',
      'get:/knowledge/v1/mall/product/user/page/buy',
      'get:/knowledge/v1/law/agreement/page/list/search',
      'post:/knowledge/v1/user/compare/log/compare',
      'get:/knowledge/v1/user/compare/log/page/list',
      'post:/operation/v1/office/new',
      'post:/operation/v1/office/config',
      'post:/office-convert/v1/to/html',
      'get:/knowledge/v1/mall/document/page/list',
      'post:/operation/v1/user/current/wechat/unbind',
      'get:/knowledge/v1/law/document/page/scene/list',
      'put:/knowledge/v1/law/agreement/service/client/remark/{id}',
      'put:/knowledge/v1/law/agreement/service/annex/{id}',
      'get:/knowledge/v1/user/count/status/client',
      'get:/knowledge/v1/questionnaire/index',
      'get:/knowledge/v1/questionnaire/online/{id}',
      'post:/knowledge/v1/questionnaire/online/submit',
      'get:/knowledge/v1/questionnaire/report',
      'get:/knowledge/v1/questionnaire/user/page',
      'get:/knowledge/v1/questionnaire/all/free',
      'get:/knowledge/v1/questionnaire/radar/chart',
      'get:/knowledge/v1/questionnaire/online/free/{categoryId}',
      'post:/office-convert/v1/to/pdf',
      'get:/knowledge/v1/user/buy/course/page',
      'get:/knowledge/v1/course/logs/page/list',
      'post:/knowledge/v1/user/agreement',
      'get:/knowledge/v1/user/agreement/page/list',
      'get:/knowledge/v1/user/agreement/collaborate/{contractId}',
      'put:/knowledge/v1/user/agreement/collaborate',
      'get:/knowledge/v1/user/agreement/collaborate/page/list',
      'get:/knowledge/v1/user/agreement/{id}',
      'get:/knowledge/v1/user/agreement/flow/page/list',
      'get:/knowledge/v1/user/agreement/flow/{id}',
      'post:/knowledge/v1/user/agreement/flow',
      'put:/knowledge/v1/user/agreement/flow/{id}',
      'delete:/knowledge/v1/user/agreement/flow/{id}',
      'put:/knowledge/v1/user/agreement/flow/status/{id}',
      'get:/knowledge/v1/user/agreement/flow/all',
      'put:/knowledge/v1/user/agreement',
      'get:/knowledge/v1/user/agreement/page/count',
      'get:/knowledge/v1/user/contract/performance/page/list',
      'get:/knowledge/v1/user/contract/performance/{id}',
      'post:/knowledge/v1/user/contract/performance',
      'delete:/knowledge/v1/user/contract/performance/{id}',
      'get:/knowledge/v1/user/onsite/service/page/list',
      'get:/knowledge/v1/user/onsite/service/{id}',
      'get:/knowledge/v1/user/content/list/new',
      'get:/knowledge/v1/user/content/right/count',
      'get:/knowledge/v1/user/content/mechanism/count',
      'get:/knowledge/v1/law/rule/info/{id}',
      'get:/knowledge/v1/org/question/index',
      'get:/knowledge/v1/org/question/info/{id}',
      'get:/knowledge/v1/org/question/list',
      'get:/knowledge/v1/org/question/other',
      'get:/knowledge/v1/law/noun/all',
      'get:/knowledge/v1/law/noun/by/titles',
      'get:/knowledge/v1/law/case/hots',
      'get:/knowledge/v1/law/question/hots',
      'get:/knowledge/v1/law/page/lists',
      'get:/knowledge/v1/user/content/list',
      'post:/knowledge/v1/mall/order/document/add/use/{id}',
      'post:/knowledge/v1/questionnaire/online/draft',
      'post:/sms/v1/getIdentifyingCode',
      'get:/operation/v1/common/code/sign',
      'get:/operation/v1/common/code/sign/{id}',
      'get:/knowledge/v1/sign/contract/count/status',
      'post:/knowledge/v1/user/open',
      'post:/knowledge/v1/user/org/open',
      'get:/knowledge/v1/sign/contract/page/list',
      'get:/knowledge/v1/sign/contract/count',
      'get:/knowledge/v1/sign/counterpart/page/list',
      'get:/knowledge/v1/sign/counterpart/{id}',
      'post:/knowledge/v1/sign/counterpart',
      'put:/knowledge/v1/sign/counterpart/{id}',
      'delete:/knowledge/v1/sign/counterpart/{id}',
      'post:/knowledge/v1/sign/contract/draft',
      'post:/knowledge/v1/sign/contract',
      'get:/knowledge/v1/sign/contract/{id}',
      'put:/knowledge/v1/sign/contract/nullify',
      'put:/knowledge/v1/sign/contract/extension',
      'delete:/knowledge/v1/sign/contract/{id}',
      'get:/knowledge/v1/sign/seal/page/list',
      'post:/knowledge/v1/sign/seal',
      'delete:/knowledge/v1/sign/seal/{id}',
      'get:/knowledge/v1/sign/seal/{id}',
      'get:/operation/v1/common/auth/person/face/{id}',
      'get:/operation/v1/common/auth/person/face/{id}/{token}',
      'get:/operation/v1/common/auth/person/face/{id}/{token}/status',
      'get:/operation/v1/user/current/auth/log',
      'get:/operation/v1/orga/{orgaId}/auth/log',
      'get:/knowledge/v1/sign/contract/sms/code/{no}',
      'put:/knowledge/v1/sign/contract/sms/code/{no}',
      'put:/knowledge/v1/sign/contract/submit',
      'get:/wechat/v1/info/mini/{appid}/scheme',
      'put:/knowledge/v1/sign/contract/sms/send',
      'delete:/knowledge/v1/user/clean',
      'post:/office-convert/v1/to/pdf/sign',
      'post:/sms/v1/validCode',
      'put:/knowledge/v1/user/default/{id}',
      'put:/knowledge/v1/sign/contract/performance/{id}',
      'get:/knowledge/v1/sign/contract/completed/page/list',
      'get:/knowledge/v1/sign/contract/log/page/list',
      'put:/knowledge/v1/sign/contract/apply/chain',
      'get:/knowledge/v1/sign/contract/apply/chain',
      'get:/knowledge/v1/sign/contract/certificate/info/{id}',
      'get:/knowledge/v1/sign/contract/active',
      'get:/operation/v1/common/dict/bank',
      'post:/operation/v1/orga/{orgaId}/auth/log/{id}/missive',
      'post:/knowledge/v1/sign/seal/auth',
      'get:/knowledge/v1/sign/seal/auth/page/list',
      'delete:/knowledge/v1/sign/seal/auth/{id}',
      'get:/knowledge/v1/sign/seal/auth/log/page/list',
      'get:/knowledge/v1/sign/seal/sign/page/list',
      'post:/knowledge/v1/file/pdf2html',
      'put:/knowledge/v1/user/agreement/cancel',
      'get:/knowledge/v1/sign/seal/auth/seal/{sealId}',
      'get:/knowledge/v1/user/onsite/service/client/page/list',
      'get:/knowledge/v1/user/onsite/service/client/count',
      'post:/knowledge/v1/user/onsite/service/user',
      'put:/knowledge/v1/user/onsite/service/cancel/{id}',
      'put:/knowledge/v1/user/onsite/service/client/{id}',
      'post:/knowledge/v1/user/onsite/service/complete',
      'get:/knowledge/v1/mall/document/package/{packageId}/doc/{id}',
      'put:/knowledge/v1/mall/product/use/{id}',
      'get:/knowledge/v1/questionnaire/count/risk',
      'get:/knowledge/v1/mall/course/{id}',
      'get:/knowledge/v1/mall/course/page/list',
      'get:/knowledge/v1/user/up/{id}',
      'get:/knowledge/v1/mall/course/chapter/{id}',
      'put:/knowledge/v1/mall/course/{id}',
      'put:/knowledge/v1/course/schedule/chapter/{id}',
      'get:/operation/v1/orga/{orgaId}/counselor/qrcode',
      'get:/knowledge/v1/msg/message/unRead',
      'get:/knowledge/v1/msg/message/page/list',
      'get:/knowledge/v1/user/org/services/info',
      'get:/knowledge/v1/org/consult/page/list/client',
      'post:/knowledge/v1/file/content'
    ]
  }
}
