const cbs = {}

window.addEventListener('message', e => {
  if (typeof (e.data) === 'string' && e.data.startsWith('freshen')) {
    const arr = e.data.split('.')
    if (arr[1]) {
      setTimeout(() => {
        cbs[arr[1]]()
      }, 2000)
    }
  }
})

export default {
  install (Vue) {
    Vue.prototype.gotoGoods = function (code, callback) {
      const id = new Date().getTime()
      window.open(`https://pay.ftling.com/#/user/goods/${code}?cbid=${id}`)
      cbs[id] = callback
    }
    Vue.prototype.gotoOrder = function (orderId, callback) {
      const id = new Date().getTime()
      window.open(`https://pay.ftling.com/#/user/order/${orderId}?cbid=${id}`)
      cbs[id] = callback
    }
  }
}
