export default {
  install (Vue) {
    Vue.directive('img-async-load', function (el, binding) {
      if (binding.value && binding.oldValue !== binding.value) {
        const img = new Image(169, 220)
        img.src = binding.value
        img.onload = () => {
          if (!el.src.startsWith('blob:')) {
            el.src = img.src
          }
          img.remove()
        }
      }
    })
  }
}
